<template>
  <div
    v-if="hasSquintPluginData"
    style="
      border-radius: 50%;
      background-color: #383d44;
      color: #FFF;
      display: inline-block;
      padding: 0;
      margin: 0;
      width: 17px;
      height: 17px;
      text-align: center;
      font-weight: bold;
      cursor: pointer
    "
    @click="isDialogActive = true"
  >
    S
    <v-dialog v-model="isDialogActive" scrollable max-width="450px">
      <ActionConfirmationDialog
        header="Squint Metrics Data"
        subheader="You can view statuses and dates for Squint Metrics API usage"
        action-button-name="Close"
        cancel-button-name=""
        @action="isDialogActive = false"
      >
        <template #extra>
          <div style="margin-top: 20px;color: #000;">
            Data for <strong>{{ contactEmail.emailAddress }}</strong>
          </div>
          <div
            v-if="hasOptedInStatus"
            style="margin-top: 20px;color: #000;"
          >
            Opt-in Status: {{ pluginData.optInStatus }}<br>
            <span v-if="pluginData.optInStatusMessage">
              Opt-in Status Message: {{ pluginData.optInStatusMessage }}
            </span>
            <br v-if="pluginData.optInStatusMessage">
            Opt-in Sent Date: {{ formatDate(pluginData.optInSentDate) }}<br>
          </div>
          <div
            v-if="hasOptedOutStatus"
            style="margin-top: 20px;color: #000;"
          >
            Opt-out Status: {{ pluginData.optOutStatus }}<br>
            <span v-if="pluginData.optOutStatusMessage">
              Opt-out Status Message: {{ pluginData.optOutStatusMessage }}
            </span>
            <br v-if="pluginData.optOutStatusMessage">
            Opt-out Sent Date: {{ formatDate(pluginData.optOutSentDate) }}<br>
          </div>
        </template>
      </ActionConfirmationDialog>
    </v-dialog>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import ActionConfirmationDialog from "@/sharedComponents/ActionConfirmationDialog";

export default {
  name: "SquintMetricsEmailIcon",
  components: {
    ActionConfirmationDialog,
  },
  mixins: [datesMixin],
  props: {
    contactEmail: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isDialogActive: false,
    };
  },
  computed: {
    hasSquintPluginData() {
      return !!this.pluginData;
    },
    pluginData() {
      return this.contactEmail?.pluginData?.squint_metrics_plugin;
    },
    hasOptedInStatus() {
      return !!this.pluginData?.optInStatus;
    },
    hasOptedOutStatus() {
      return !!this.pluginData?.optOutStatus;
    },
  },
  methods: {
    formatDate(date) {
      return this.getDateWithDayNameAndTimeInUserTimeZone(date);
    },
  }
};
</script>

<style scoped>
</style>