<template>
  <v-card class="custom-field-popup-card">
    <v-card-title class="px-5 pt-4 pb-4">
      <div>{{ header }}</div>
    </v-card-title>
    <v-card-text class="pa-5 pt-2">
      <div class="notification-menu__header" style="color: #66788e;">
        <slot name="subheader">
          {{ subheader }}
        </slot>
        <slot name="extra" />
      </div>
    </v-card-text>

    <v-card-actions
      class="d-flex justify-end px-5 pb-3 pt-3"
    >
      <button
        v-if="cancelButtonName"
        type="button"
        role="button"
        :class="cancelButtonClass"
        @click="$emit('dismiss')"
      >
        {{ cancelButtonName }}
      </button>
      <v-btn
        v-if="actionButtonName"
        :class="actionButtonClass"
        @click="$emit('action')"
      >
        {{ actionButtonName }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "ActionConfirmationDialog",
  components: {},
  props: {
    header: {
      default: () => "Action Confirmation",
      type: String,
    },
    subheader: {
      default: () => "Do you really want to this action?",
      type: String,
    },
    actionButtonName: {
      default: () => "Action",
      type: String,
    },
    cancelButtonName: {
      default: () => "Cancel",
      type: String,
    },
    actionButtonClass: {
      default: 'simple-text-button button--red elevation-0 px-7',
      type: String,
    },
    cancelButtonClass: {
      default: 'simple-text-button button--gray px-6',
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

